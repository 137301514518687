import assets from 'assets/*.jpg';

const team = [{
    name: 'Annemieke Oomen',
    site: 'https://www.annemiekeoomen.nl',
    mail: 'info@annemiekeoomen.nl',
    title: 'Psychotherapeut',
    src: assets.annemieke
},
{
    name: 'Carla Welink',
    phone: '',
    mail: 'cwelink@xs4all.nl',
    title: 'Psychotherapeut',
    src: assets.carla
},
{
    name: 'Cobie Groenendijk',
    site: 'https://mentalmente.nl/psychiater/',
    mail: 'c.groenendijk@mentalmente.nl',
    title: '(K & J) Psychiater',
    src: assets.cobie
},
{
    name: 'Esther de Wolf',
    phone: '0615000816',
    mail: 'contact@estherdewolf.nl',
    title: 'Psychotherapeut',
    src: assets.esther
},
{
    name: 'Pauline Krenn',
    site: 'http://www.relatietherapiezuid.nl',
    mail: '06-30114182',
    title: 'Systeemtherapeut',
    src: assets.pauline
},
{
    name: 'Silke Fromm',
    site:'https://www.silkefromm.nl/',
    mail:'info@silkefromm.nl',
    title: 'Psychotherapeut',
    src: assets.silke
},
{
    name: 'Susan Wever',
    site: 'https://www.psychotherapie-wever.nl',
    mail: 'info@psychotherapie-wever.nl',
    title: 'Psychotherapeut',
    src: assets.susan
},
{
    name: 'Tessa Kaarsgaren',
    site: 'http://www.piworks.nl',
    mail: 'tessapiworks@gmail.com',
    title: 'Psychotherapeut',
    src: assets.tessa
},
{
    name: 'Theo Verbeek',
    site: 'https://www.theoverbeek.nl',
    phone: '06-10707520',
    title: 'Psychotherapeut',
    src: assets.theo
},
{
    name: 'Thomas Lenz',
    site: 'https://www.praktijklenz.nl',
    mail: 'claire@claireassendelft.nl',
    title: 'Psychiater',
    src: assets.thomas
}
];


const initializeGrid = (data) => {
    const el = document.createElement('div');
    el.innerHTML = data;
    document.querySelector('.team').appendChild(el);
    
}

const createGrid = (data) => {
    return `<div class="grid" id="team">
        ${data.map((el) => createGridItem(el)).join('')}
        </div>`
}

const createGridItem = (data) => {
    if (!data) return;
        return `<div class="grid-item">
            <img src="${data.src}" alt="${data.name}" class="grid-item-image" />
            <div class="grid-item-content">
                <div class="item-text">
                    <div>
                        <h3 class="medium-title" > ${data.name} </h3>
                        <h4 class="small-title" > ${data.title} </h4>
                    </div>
                </div>
                <div class="info" >

                    ${data.site ? `<a href="${data.site}" target="_blank" > ${data.site.replace(/.*www\./, '').replace(/([^nl]*$)/, '')} </a>` : ''}
                    ${data.phone ? `<span> ${data.phone} </span>` : ''}
                    ${data.mail ? `<a href="mailto:${data.mail}" > ${data.mail} </a>` : ''}
                </div>
            </div>
        </div>`
}

const $ = (e, q) => {
    return e.querySelectorAll(q);
}

const toggleClass = (e, q) => {
    e.classList.contains(q) ? e.classList.remove(q) : e.classList.add(q);
}

let offsetBase = 0;
const menuHeight = 77;
const logoAddHeight = 100;
const navWrap = document.querySelector('nav.container-wrap');
const navContent = document.querySelector('.nav-content');
const logo = document.querySelector('.logo-style.nav');

const handler = () => {
    const offsetTop = window.scrollY || window.pageYOffset;

    if (offsetTop > logoAddHeight){
        logo.classList.add('show');
    } else{
        logo.classList.remove('show')
    }

    if (offsetTop > menuHeight) {
        if (offsetTop > offsetBase) {
            navWrap.classList.remove('animateIn');
            navWrap.classList.add('animateOut');
        } else {
            navWrap.classList.remove('animateOut');
            navWrap.classList.add('animateIn');
        }
            navContent.style.background = 'white';
            offsetBase = offsetTop;
    } else {
        navContent.style.backgroundColor = 'white';
    }
}

const navSlide = () => {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.nav-links');
    const navLinks = $(document, '.nav-links li');
    const menuItem = $(document, '.nav-links li a');

    menuItem.forEach((link) => {
        link.addEventListener('click', () => {
            burger.click();
        })
    });

    burger.addEventListener('click', () => {
        toggleClass(nav, 'nav-active');
            navLinks.forEach((link, index) => {
                if (link.style.animation) {
                    link.style.animation = '';
                } else {
                    link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.5}s`;
                }
            });
        toggleClass(burger, 'toggle');
    });
}

//if scrollHeight > 120, add class show to nav menu, otherwise remove it.

const skipLink = () => {
    const skipLink = document.createElement('div');
    skipLink.innerHTML = `<div class="skip" > <a class="skip-link" href="#about"> Spring naar de inhoud </a></div >`;
    document.body.insertBefore(skipLink, document.body.firstChild);
}

window.addEventListener('scroll', handler, false);

navSlide();
initializeGrid(createGrid(team));
skipLink();
